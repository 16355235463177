html,
body {
  height: 100vh;
}
body {
  background: #eee;
  color: #111;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.floating-top {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  padding: 15px;
  z-index: 1000;
  text-align: right;
  background: rgba(240, 240, 240, 0.9);
}
.floating-top * {
  padding: 0 10px;
}
.overlay {
  position: absolute;
  width: 60vw;
  height: 60vh;
  left: 20vw;
  top: 20%;
  background: rgba(1, 1, 1, 0.8);
  padding: 20px;
}
@media screen and (max-width: 450px) and (orientation: portrait) {
  .overlay {
    position: absolute;
    width: 90vw;
    height: 80vh;
    left: 5vw;
    top: 15%;
  }
}
@media screen and (max-height: 450px) and (orientation: landscape) {
  .overlay {
    position: absolute;
    width: 90vw;
    height: 80vh;
    left: 5vw;
    top: 15%;
  }
}
section {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
}
section h1 {
  font-weight: 900;
}
section p.lead {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-style: italic ;
}
section blockquote {
  position: absolute;
  bottom: 10px;
  color: #eee;
  text-shadow: 1px 1px 1px rgba(1, 1, 1, 0.8);
}
section label {
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
}
section.holding {
  background: #eee url(https://picsum.photos/id/168/800/600?blur) 50% 50% no-repeat;
  background-size: cover;
}
section.holding h1,
section.holding p.lead,
section.holding p {
  color: #eee;
}
section.holding .overlay {
  background: #eee url(https://picsum.photos/id/505/800/600) 50% 50% no-repeat;
  background-size: cover;
}
section.moreinfo {
  background: #333 url(https://picsum.photos/id/168/800/600?blur) 50% 50% no-repeat;
  background-size: cover;
}
section.moreinfo h1,
section.moreinfo p.lead,
section.moreinfo p {
  color: #eee;
}
section.moreinfo .overlay {
  background: #eee url(https://picsum.photos/id/3/800/600) 50% 50% no-repeat;
  background-size: cover;
}
